
export enum Locales {
    enUS = 'en-US',
    esES = 'es-ES',
    esMX = 'es-MX',
    frFR = 'fr-FR',
    itIT = 'it-IT'
}

export enum TimeRange {
    Minutes = 0,
    Hours = 1,
    Days = 2,
    Weeks = 3,
    Months = 4,
    Years = 5,
}

export enum CustomFieldType {
    Text = 0,
    Bool = 1,
    PickList = 2,
}


export enum TextBlockType { Posts = 0, Testimonials = 1, Teams = 2 }
export enum SortType { ByNew = 1, ByPriceAsc = 2, ByPriceDesc = 3 }
export enum SortTypeBlog { ByNew = 1, ByOld = 2 }
export enum DiscountType { Money = 0, Percent = 1 };
export enum ModifyMode { Increment = 0, Decrement = 1, Exact = 2 };
export enum PaymentMethodType { Redsys = 1, MoneyVoucher = 6, SessionsVoucher = 7, HoursVoucher = 8, PayPal = 9, NetPay = 10, Stripe = 14, AuthorizeNet = 15, MercadoPago = 17, Transbank = 18, Pending = 5 };
export enum DatePickerMode {
    DateTime = 0,
    Date = 1,
    Time = 2
}

export const IsMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


export enum ProductCategory {
    Service = 0,
    Article = 1,
    PeriodicSale = 2,
    VoucherCampaign = 3
}

export enum BookingOrigin {
    Taykus_Web = 2,
    Taykus_Android = 3,
    Taykus_iOS = 4,
    CZ_Web = 5,
    CZ_Android = 6,
    CZ_iOS = 7
}

export enum BookingPaymentType {
    NotSet = 0,
    Full = 1,
    Shared = 2,
    ByPlace = 3
}

export enum BookingTeam {
    NotSet = -1,
    Team1 = 0,
    Team2 = 1,
}

export interface TransbankPaymentQuery {
    TBK_TOKEN?: string;
    TBK_ID_SESION?: string;
    TBK_ORDEN_COMPRA?: string;
}